<template>
  <div>
    <Modal v-model="editStatus" title="选择银行logo" width="500" scrollable footer-hide>
      <div class="choose-logo-m">
        <div class="choose-logo" v-for="(item,index) in data" :key="index"  @click="chooseLogo(item.bank_code)">
          <img class="logo" :src="ApiUrl + '/static/bank_logo/' + item.bank_code + '.png'" alt="">
          <Button class="btn" size="small" type="primary" ghost>选择</Button>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
export default {
  name:'editBank',
  props:{
    BankName:{
      type:String,
      default:''
    }
  },
  data() {
    return {
      editStatus:false,
      data:[]
    }
  },
  methods:{
    init:function(){
      let _this = this;
      this.requestApi('/adm/getBankLogo.html',{BankName:this.BankName}).then(function (res){
        if(res.status == 1){
          _this.data = res.data;
          _this.editStatus = true;
        }
      })
    },
    chooseLogo:function(bankCode){
      this.$emit('chooseLogo',{bankCode:bankCode})
    },
    close:function(){
      this.editStatus = false;
    }
  }
}
</script>
<style lang="less">
.choose-logo-m{
  max-height: 400px;overflow: auto;
  .choose-logo{
    display:block; width: auto;height: auto;border:1px solid #eeeeee;padding:10px;margin-bottom:15px;cursor: pointer;
    .logo{
      display: inline-block;width: auto;height: 30px;
    }
    .btn{
      float: right;margin-top:5px;
    }
    &:hover{
      background-color: #f5f5f5;
    }
  }
}
</style>
