<template>
  <div class="syssetting">
    <div class="breadcrumb-m">
      <div class="left">
        <Breadcrumb>
          <BreadcrumbItem to="/">主控台</BreadcrumbItem>
          <BreadcrumbItem>配置管理</BreadcrumbItem>
          <BreadcrumbItem>银行卡管理</BreadcrumbItem>
        </Breadcrumb>
      </div>
      <div class="right">
        <Button type="info" icon="ios-add" @click="addBank(-1)">新增银行</Button>
      </div>
    </div>
    <div class="ser-m">
      <Form ref="formInline" inline :label-width="70">
        <FormItem label="银行名称">
          <Input type="text" @on-keyup.13="init" v-model="serData.name" placeholder="银行名称" maxlength="20"></Input>
        </FormItem>
        <FormItem label="启用状态">
          <Select style="width: 120px" v-model="serData.status" @on-change="init">
            <Option :value="2">全部</Option>
            <Option :value="1">启用</Option>
            <Option :value="0">关闭</Option>
          </Select>
        </FormItem>
        <FormItem :label-width="0">
          <Button type="primary" @click="init" @keyup.13="init">搜索</Button>
        </FormItem>
      </Form>
    </div>
    <Table size="small" :columns="columns" :data="data" :loading="loading">
      <template slot-scope="item" slot="key">
        <img style="height: 18px;width: auto;" :src="ApiUrl + '/static/bank_logo/' + item.row.code + '.png'" alt="">
      </template>
      <template slot-scope="item" slot="status">
        <i-switch size="large" :true-value="1" :false-value="0" v-model="item.row.status" @on-change="upBank(item.row.id,'status',$event)">
          <span slot="open">启用</span>
          <span slot="close">关闭</span>
        </i-switch>
      </template>
      <template slot-scope="item" slot="is_def">
        <i-switch size="large" :true-value="1" :false-value="0" v-model="item.row.is_def" @on-change="upBank(item.row.id,'is_def',$event)">
          <span slot="open">是</span>
          <span slot="close">否</span>
        </i-switch>
      </template>
      <template slot-scope="item" slot="action">
        <Button size="small" type="primary" @click="addBank(item.index)">修改</Button>
        <Button size="small" style="margin-left: 10px" type="warning" @click="delBank(item.row.id)">删除</Button>
      </template>
    </Table>
    <div class="footer-m">
      <Page class-name="pagew page-r" :total="total" :current="page" show-total show-elevator show-sizer :page-size="page_size" :page-size-opts="[15,30,50]" @on-change="change_page" @on-page-size-change="change_page_size"/>
    </div>
    <edit-bank ref="editBankRef" @saveOk="saveOk"></edit-bank>
  </div>
</template>
<script>
import editBank from '@/views/sysset/bank_mana/edit_bank'
export default {
  name: 'BankMana',
  components:{
    editBank
  },
  data(){
    return {
      columns:[
        {title: '编号',key: 'id',minWidth:80},
        {title: '银行logo',slot: 'key',minWidth:150},
        {title: '银行名称',key: 'name',minWidth:150},
        {title: '状态',slot: 'status',minWidth:80},
        {title: '是否默认',slot: 'is_def',minWidth:80},
        {title: '更新时间',key: 'up_date',minWidth:140},
        {title: '操作',slot:'action',width:120,align:'center'},
      ],
      data:[],
      serData:{name:'',status:2},
      page:1,
      page_size:0,
      total:0,
      loading:false,
      editStatus:false,
      checkIndex:-1,
      editData:{id:0, name:'',code:'',status:1, is_def:0}
    }
  },
  created() {
    this.init();
  },
  methods:{
    init:function (){
      let _this = this;
      this.loading = true;
      this.requestApi('/adm/getBankList.html',{page_size:this.page_size,page:this.page,ser_data:this.serData}).then(function (res){
        _this.loading = false;
        if(res.status==1){
          _this.total = res.page.total;
          _this.current_page = res.page.current;
          _this.page_size = res.page.limit;
          _this.data = res.list;
        }
        _this.$nextTick(()=>{
          _this.checkIndex = -1;
        })
      })
    },
    addBank:function(index){
      this.checkIndex = index;
      if(index === -1) this.$refs.editBankRef.open(this.editData);
      else this.$refs.editBankRef.open(this.data[index]);
    },
    saveOk:function(){
      if(this.checkIndex != -1) this.page = 1;
      this.init();
    },
    delBank:function(id){
      this.$Modal.confirm({
        title: '提示',
        content: '<p>确定要删除吗？</p>',
        onOk: () => {
          this.upBank(id,'del',null);
        },
        onCancel: () => {
          this.$Message.warning('取消删除');
        }
      });
    },
    upBank:function(id,action,val){
      let _this = this;
      this.requestApi('/adm/upBank',{id:id,action:action,val:val}).then(function (res) {
        if (res.status == 1) {
          _this.$Message.success(res.msg);
          if(action == 'del' && _this.data.length == 1) _this.page--;
          _this.init();
        }else _this.$Message.error(res.msg);
      })
    },
    change_page:function (page){
      this.page = page;
      this.init();
    },
    change_page_size:function (pageSize){
      this.page_size = pageSize;
      this.page = 1;
      this.init();
    }
  }
}
</script>
<style lang="less">

</style>