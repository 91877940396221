<template>
  <div v-if="editStatus">
    <Modal v-model="editStatus" :title="editData.id?'编辑银行':'新增银行'" width="500" @on-ok="commitData" @on-cancel="close" :loading="subLoading">
      <Form label-position="left" :label-width="70" ref="fValidate" :model="editData" :rules="ruleValid" >
        <FormItem label="银行名称" style="margin-bottom: 10px" prop="name">
          <Input v-model="editData.name" maxlength="20"></Input>
        </FormItem>
        <FormItem v-show="editData.name.length > 0" label="" style="margin-bottom: 10px">
          <Button type="primary" icon="md-add" @click="chooseImg">选择logo</Button>
        </FormItem>
        <FormItem v-if="editData.code != ''" label="银行logo" style="margin-bottom: 10px">
          <img style="height: 30px;width: auto;" :src="ApiUrl + '/static/bank_logo/' + editData.code + '.png'" alt="">
        </FormItem>
        <FormItem label="启用状态" style="margin-bottom: 10px">
          <i-switch size="large" :true-value="1" :false-value="0" v-model="editData.status">
            <span slot="open">启用</span>
            <span slot="close">关闭</span>
          </i-switch>
        </FormItem>
        <FormItem label="设为默认" style="margin-bottom: 10px">
          <i-switch size="large" :true-value="1" :false-value="0" v-model="editData.is_def">
            <span slot="open">是</span>
            <span slot="close">否</span>
          </i-switch>
        </FormItem>
      </Form>
    </Modal>
    <choose-logo ref="chooseLogo" :BankName="editData.name" @chooseLogo="chooseLogo"></choose-logo>
  </div>
</template>
<script>
import chooseLogo from '@/views/sysset/bank_mana/choose_logo'
export default {
  name:'editBank',
  components:{
    chooseLogo
  },
  data() {
    return {
      editStatus:false,
      subLoading: true,
      isInit:false,
      editData: {id:0, name:'',code:'', status:1, is_def:0},
      ruleValid: {
        name: [
          { required: true, message: '此项必填', trigger: 'blur' }
        ]
      }
    }
  },
  watch:{
    'editData.name'(){
      if(this.isInit) this.editData.code = '';
    },
    subLoading(e){
      if(!e)
        setTimeout(()=>{
          this.subLoading = true;
        },50)
    }
  },
  methods:{
    open:function(editData){
      this.isInit = false;
      this.editStatus = true
      this.editData = JSON.parse(JSON.stringify(editData));
      this.$nextTick(()=>{
        this.isInit = true;
      })
    },
    commitData:function(){
      let _this = this;
      this.$refs.fValidate.validate((valid) => {
        if (valid) {
          this.requestApi('/adm/comBank.html',{editData:this.editData}).then(function (res){
            _this.subLoading = false;
            if(res.status==1){
              _this.$Message.success(res.msg);
              _this.$emit('saveOk', {reData:res.data});
              _this.editStatus = false;
            }else{
              _this.subLoading = false;
              _this.$Message.error(res.msg);
            }
          })
        }else{
          this.subLoading = false;
          this.$Message.error('数据未完善!');
        }
      })
    },
    close:function(){
      this.editStatus = false;
    },
    chooseImg:function (){
      this.$refs.chooseLogo.init();
    },
    chooseLogo:function(e){
      this.editData.code = e.bankCode;
      this.$refs.chooseLogo.close();
    }
  }
}
</script>
